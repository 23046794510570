const sizeRegex = /(^.*)h_\d+,w_\d+(.*$)/;
const originalRegex = /(^.*)\/.*(\/.*$)/;

export const isMelevenUrl = (url: string) => url ? url.includes('api.meleven.de') : false;
export const isCloudinaryUrl = (url: string) => url ? url.includes('res.cloudinary.com') : false;

export const resizeImage = (url: string, width: number, height: number) =>
  isMelevenUrl(url) ? url.replace(sizeRegex, `$1h_${height},w_${width}$2`) : url;

export const convertImageToWebp = (url: string) => {
  if (!url) {
    return '';
  }

  return isMelevenUrl(url)
    ? url.replace('/engbers/', '/engbers/o_extension,e_webp,')
    : isCloudinaryUrl(url)
      ? convertCloudinaryImageToWebp(url)
      : url;
}

export const convertCloudinaryImageToWebp = (url: string) => {
  const urlSplit = url.split('.');

  if (urlSplit[urlSplit.length - 1] !== 'svg') {
    urlSplit.pop();
    urlSplit.push('webp');
  }

  return urlSplit.join('.');
};

export const getOriginalSize = (url: string) => (isMelevenUrl(url) ? url.replace(originalRegex, '$1$2') : url);
